<template>
  <div class="main-container">
    <v-title>
      <Breadcrumb separator=">">
        <Breadcrumb-item><i class="icon-home"></i>首页</Breadcrumb-item>
        <Breadcrumb-item>信息提醒</Breadcrumb-item>
      </Breadcrumb>
    </v-title>
    <div class="header">
      <span>所属门店</span>
      <Select v-model="query.branch_id" style="width:200px" @on-change="getResult">
        <Option value="all">全部</Option>
        <Option v-for="item in branchList" :value="item.id" :key="item.id">{{ item.name }}</Option>
      </Select>
      <span>筛选数据</span>
      <Select v-model="query.time_type" style="width:200px" @on-change="onChangeTimeType">
        <Option value="今日">今日</Option>
        <Option value="本周">本周</Option>
        <Option value="上周">上周</Option>
        <Option value="本月">本月</Option>
        <Option value="上月">上月</Option>
        <Option value="最近30天">最近30天</Option>
      </Select>
      <span>日期选择</span>
      <DatePicker
        type="date"
        placeholder="选择日期"
        style="width: 200px"
        v-model="query.start_time"
        @on-change="onChangeStartDate"
      />
      <span>至</span>
      <DatePicker
        type="date"
        placeholder="选择日期"
        style="width: 200px"
        v-model="query.end_time"
        :options="disabledDate"
        @on-change="onChangeEndDate"
        :disabled="!query.start_time"
      />
    </div>
    <div class="content">
      <ul class="info-wrapper">
        <li @click="$router.push('/base/member/newMember')">
          <div class="main-module bg1">
            <div class="left-container">
              <p>顾客建档</p>
              <p>顾客新建档案</p>
            </div>
            <div class="right-container">
              <p class="result">{{ result.d1 || 0 }}</p>
              <p>（建档人数）</p>
            </div>
          </div>
        </li>
        <li @click="$router.push('/base/home/report/all')">
          <div class="main-module bg2">
            <div class="left-container">
              <p>报告上传</p>
              <p>报告查询、报告上传</p>
            </div>
            <div class="right-container">
              <p class="result">{{ result.d2 || 0 }}</p>
              <p>（检测人数）</p>
            </div>
          </div>
        </li>
        <li @click="$router.push('/base/statistics/train')">
          <div class="main-module bg3">
            <div class="left-container">
              <p>训练</p>
              <p>器械训练、团课训练</p>
            </div>
            <div class="right-container">
              <p class="result">{{ result.d3 || 0 }}</p>
              <p>（训练人数）</p>
            </div>
          </div>
        </li>
        <li @click="$router.push('/base/plan')">
          <div class="main-module bg4">
            <div class="left-container">
              <p>方案</p>
              <p>会员方案出具调整</p>
            </div>
            <div class="right-container">
              <p class="result">{{ result.d4 || 0 }}</p>
              <p>（方案交付人数）</p>
            </div>
          </div>
        </li>
        <li @click="$router.push('/base/promotion-manage')">
          <div class="main-module bg5">
            <div class="left-container">
              <p>促进</p>
              <p>店访、家访、促进服务</p>
            </div>
            <div class="right-container">
              <p class="result">{{ result.d5 || 0 }}</p>
              <p>（促进人数）</p>
            </div>
          </div>
        </li>
        <li @click="$router.push('/base/home/class')">
          <div class="main-module bg6">
            <div class="left-container">
              <p>课程与活动</p>
              <p>健康教育与门店活动</p>
            </div>
            <div class="right-container">
              <p class="result">{{ result.d6 || 0 }}</p>
              <p>（实际人数/次）</p>
            </div>
          </div>
        </li>
        <li @click="$router.push('/base/warning')">
          <div class="main-module bg7">
            <div class="left-container">
              <p>预警</p>
              <p>血压、血糖值预警</p>
            </div>
            <div class="right-container">
              <p class="result">{{ result.d7 || 0 }}</p>
              <p>（预警人数/预警数量）</p>
            </div>
          </div>
        </li>
        <li @click="$router.push('/base/home/reconstru')">
          <div class="main-module bg8">
            <div class="left-container">
              <p>门店复健</p>
              <p>抗衰复健录入</p>
            </div>
            <div class="right-container">
              <p class="result">{{ result.d8 || 0 }}</p>
              <p>（复健人数/次数）</p>
            </div>
          </div>
        </li>
        <li @click="$router.push('/base/home/monitor')">
          <div class="main-module bg9">
            <div class="left-container">
              <p>监测服务</p>
              <p>人体成分、血压、血糖</p>
            </div>
            <div class="right-container">
              <p class="result">{{ result.d9 || 0 }}</p>
              <p>（监测服务人数）</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="prompt" v-show="showModal">
      <img src="@/assets/img/home-roulette.png" class="prompt-img" />
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import vTitle from 'components/topHeader/topHeader';
import accountService from '@/services/accountService';
export default {
	data() {
		return {
			query: {
				time_type: '本月',
				start_time: '',
				end_time: '',
				branch_id: 'all',
			},
			branchList: [],
			result: {},
			disabledDate: {},
			showModal: false,
			timer: null,
		};
	},
	created() {
		accountService.getHomeBranch().then((data) => {
			this.branchList = data;
		});
		this.getResult();
	},
	destroyed() {
		clearTimeout(this.timer);
	},
	components: {
		'v-title': vTitle,
	},
	methods: {
		onChangeTimeType() {
			this.query.start_time = '';
			this.query.end_time = '';
			this.getResult();
		},
		onChangeStartDate(val) {
			this.query.start_time = val;
			this.query.end_time = '';
			const self = this;
			this.disabledDate = {
				disabledDate(date) {
					return date && date.valueOf() < new Date(self.query.start_time).getTime();
				},
			};
		},
		onChangeEndDate(val) {
			this.query.end_time = val;
			if (val) this.getResult();
		},
		getResult() {
			accountService.getHomeResult(this.query).then((data) => {
				this.result = data;
				const isFirstLogin = localStorage.getItem('isFirstLogin');
				if (isFirstLogin) {
					this.$nextTick(() => {
						localStorage.removeItem('isFirstLogin');
						this.showModal = true;
						this.timer = setTimeout(() => {
							this.showModal = false;
						}, 3 * 1000);
					});
				}
			});
		},
	},
};
</script>

<style lang="less" scoped>
.main-container .main-module {
  width: 100%;
  height: 210px;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: #C8C8C8 0 2px 6px;
  display: flex;
  text-align: center;
  align-items: center;
}
.main-container .bg1 {
  background: url('@/assets/img/jiandang.png') left center no-repeat #fff;
  .result {
    color: #0f85f3;
  }
}
.main-container .bg2 {
  background: url('@/assets/img/jiance.png') left center no-repeat #fff;
  .result {
    color: #edce22;
  }
}
.main-container .bg3 {
  background: url('@/assets/img/training.png') left center no-repeat #fff;
  .result {
    color: #d97a1d;
  }
}
.main-container .bg4 {
  background: url('@/assets/img/fangan.png') left center no-repeat #fff;
  .result {
    color: #17b6cd;
  }
}
.main-container .bg5 {
  background: url('@/assets/img/cujin.png') left center no-repeat #fff;
  .result {
    color: #30c820;
  }
}
.main-container .bg6 {
  background: url('@/assets/img/course.png') left center no-repeat #fff;
  .result {
    color: #eeb120;
  }
}
.main-container .bg7 {
  background: url('@/assets/img/alarm.png') left center no-repeat #fff;
  .result {
    color: #e7301d;
  }
}
.main-container .bg8 {
  background: url('@/assets/img/birthday.png') left center no-repeat #fff;
  .result {
    color:#b14ab8;
  }
}
.main-container .bg9 {
  background: url('@/assets/img/jiance1.png') left center no-repeat #fff;
  .result {
    color: #cc3b8d;
  }
}
.left-container {
  width: 165px;
  margin-left: 215px;
  p:first-child {
    font-size: 28px;
  }
  p:last-child {
    margin-top: 15px;
    font-size: 14px;
  }
}
.right-container {
  flex: 1;
  p:first-child {
    font-size: 28px;
    font-weight: 700;
  }
  p:last-child {
    color: #afafaf;
    font-size: 14px;
    margin-top: 10px;
  }
}
.info-wrapper {
  margin-right: -1%;
}
.info-wrapper > li {
  float: left;
  width: 32.33%;
  margin-right: 1%;
  cursor: pointer;
}
.header {
  padding: 20px 15px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  span {
    margin: 0 15px;
  }
}
.prompt {
  background-color: rgba(55, 55, 55, .6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 9999;
  &-img {
    height: 600px;
    width: 600px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -300px 0 0 -300px;
  }
}
</style>
